import {
	isProduction,
	nodeEnv,
	proxyApiUrl,
	REACT_APP_COMMAND_ENDPOINT,
	REACT_APP_DOMAIN,
	REACT_APP_JWT_ENDPOINT,
	REACT_APP_MODE,
	REACT_APP_PICK_WINNERS_MIN_SECONDS,
} from './api'

const appEnv = REACT_APP_MODE
const jwtEndpoint = REACT_APP_JWT_ENDPOINT
const studioCommandEndPoint = REACT_APP_COMMAND_ENDPOINT
const appDomain = REACT_APP_DOMAIN

// Define the minimum number of seconds between start show and pick winners
// We stop accepting scores after 3 minutes from StartShow, we allow 20 seconds for processing the scores and 10 seconds for picking winners
// This value can be decreased for dev and test environments
const minSecondsBetweenStartShowAndDisplayWinners = Number(REACT_APP_PICK_WINNERS_MIN_SECONDS || 210)

export { appDomain, appEnv, isProduction, jwtEndpoint, minSecondsBetweenStartShowAndDisplayWinners, nodeEnv, proxyApiUrl, studioCommandEndPoint }

const { NODE_ENV: nodeEnv } = process.env

const isProduction = nodeEnv === 'production'

const {
	REACT_APP_DOMAIN = 'portalone.tv',
	REACT_APP_JWT_ENDPOINT = '',
	REACT_APP_COMMAND_ENDPOINT = '',
	REACT_APP_MODE = '',
	REACT_APP_PICK_WINNERS_MIN_SECONDS,
} = process.env
const proxyApiUrl = '/serverless'

module.exports = {
	REACT_APP_JWT_ENDPOINT,
	REACT_APP_COMMAND_ENDPOINT,
	REACT_APP_MODE,
	REACT_APP_DOMAIN,
	REACT_APP_PICK_WINNERS_MIN_SECONDS,
	proxyApiUrl,
	isProduction,
	nodeEnv,
}
